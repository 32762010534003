import type { PolicyOptionalGuaranteesType } from '@orus.eu/dimensions'
import assistance from './assistance.png'
import attentat from './attentat.png'
import blueHeart from './blue-heart.png'
import brisDeGlace from './bris_de_glace.png'
import catastropheNat from './catastrophe_naturelle.png'
import degatsEaux from './degats_eaux.png'
import dollarBag from './dollar_bag.png'
import euroBankNote from './euro-banknote.png'
import evenementClimatique from './evenement_climatique.png'
import { guaranteeImages } from './guarantee-images'
import hourglass from './hourglass.png'
import house from './house.png'
import incendie from './incendie.png'
import laptop from './laptop.png'
import lawBook2 from './law-book-2.png'
import lawBook3 from './law-book-3.png'
import lawBook from './law-book.png'
import ledger from './ledger.png'
import lockedKey from './locked_key.png'
import dollarFlying from './perte_exploitation.png'
import womanBehindPc from './piratage_site.png'
import recoursJuridiques from './recours_juridiques.png'
import shield from './shield.png'
import truck from './truck.png'
import vandalisme from './vandalisme.png'
import vol from './vol.png'
import womanSwimming from './woman-swimming.png'
import world from './world.png'

export const optionalGuaranteeImages: {
  [key in PolicyOptionalGuaranteesType]: string
} = {
  rce: lawBook,
  world: world,
  optionShatteredGlass: brisDeGlace,
  optionMarketValueLoss: dollarBag,
  optionOperationalLoss: dollarFlying,
  optionLegalProtection: recoursJuridiques,
  PE: dollarFlying,
  PJ: recoursJuridiques,
  RCAL: lawBook2,
  RCE: lawBook,
  ASSISTANCE: assistance,
  FREEDOM_ADVANTAGE: '',
  CYBER: '',
  MONDE: '',
  TDMI: '',
  BDG: brisDeGlace,
  BOOST: blueHeart,
  PVV: euroBankNote,
  RCT: ledger,
  cyber: womanBehindPc,
  cyberDommagesPe: shield,
  cyberDefaillanceFournisseur: truck,
  cyberDommagesTiers: lockedKey,
  cyberFraudePiratage: dollarFlying,
  tdmi: laptop,
  mrpSmallOffice: house,
  mrpSmallOfficeIncendies: incendie,
  mrpSmallOfficeDegatsDesEaux: degatsEaux,
  mrpSmallOfficeVol: vol,
  mrpSmallOfficeVandalisme: vandalisme,
  mrpSmallOfficeEvenementsClimatiques: evenementClimatique,
  mrpSmallOfficeBrisDeGlace: brisDeGlace,
  mrpSmallOfficeCatastrophesNaturelles: catastropheNat,
  mrpSmallOfficeAttentats: attentat,
  mrpSmallOfficeOccupant: lawBook3,
  mrpSmallOfficeAssistance: assistance,
  HISTORY_TAKEOVER: hourglass,
  SWIMMING_POOL: womanSwimming,
  esRcphPostDeliveryCivilLiability1: guaranteeImages.esRcphPostDeliveryCivilLiability1,
  esRcphEmployerCivilLiability1: guaranteeImages.esRcphEmployerCivilLiability1,
  esRcphOperationalCivilLiability1: guaranteeImages.esRcphOperationalCivilLiability1,
  esRcphWorldwideCoverage: guaranteeImages.esRcphWorldwideCoverage,
  esRcphOperationalCivilLiability2: guaranteeImages.esRcphOperationalCivilLiability2,
  esRcphEmployerCivilLiability2: guaranteeImages.esRcphEmployerCivilLiability2,
  esRcphPostDeliveryCivilLiability2: guaranteeImages.esRcphPostDeliveryCivilLiability2,
  esRcphCrossLiability: guaranteeImages.esRcphCrossLiability,
  esRcphPureFinancialLossLiability: guaranteeImages.esRcphPureFinancialLossLiability,
  esRcphOptionGl: guaranteeImages.esRcphPostDeliveryCivilLiability1,
}
